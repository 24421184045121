#services {
    padding: 20px;
  }
  
  .services {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .service {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 30%;
    text-align: center;
  }
  
  .service h3 {
    margin-top: 0;
  }
  